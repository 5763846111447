import { Component, ElementRef, ViewChild, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Meta, Title } from '@angular/platform-browser';


@Component({
  selector: 'app-careers',
  templateUrl: './careers.component.html',
  styleUrl: '../../../shared/style.css'
})
export class CareersComponent {


  @ViewChild('AllDepts') AllDepts: ElementRef | undefined;
  @ViewChild('Explore') Explore: ElementRef | undefined;

  @ViewChild('ExploreSide') ExploreSide: ElementRef | undefined;
  @ViewChild('AllDeptsSide') AllDeptsSide: ElementRef | undefined;

  searchControl = new FormControl('');

  constructor(private title: Title, private meta: Meta){}

  faqQuestions: any[] = [
    {
      title: "Key Account Manager", department: "Sales Department", location: "Milton Keynes", description: `The Logistics Key Account Manager will support the organisation through development of new and existing client 
management with a particular emphasis on growth of freight sales in the UAE. This role will plan and implement 
sales strategies that will gain business and effectively manage the logistics business of our clients.`, responsibilites: `<ul>
  <li>Direct and monitor the workflow of clients' businesses through sales and operational management.</li>
  <li>Develop and manage new accounts to achieve targets set.</li>
  <li>Achieve satisfactory profit/loss ratio and market share in relation to preset standards, industry and economic trends, and sales and margin goals.</li>
  <li>Ensure accuracy of data in Cargowise CRM and other programs as needed.</li>
  <li>Day-to-day account management and development of new business.</li>
  <li>Generate spot quotes / One Off Quotes.</li>
  <li>Work with internal teams to resolve account issues as needed.</li>
  <li>Ensure training and support for Cargowise web tracker (iHub) system for clients to manage their supply chain.</li>
  <li>Manage sourcing of customers. Build and maintain relationships with customers and assist and direct team members in sourcing and growing the customer base.</li>
  <li>Maintenance of current customers: Including analysis of current lanes to assure competitiveness and provide excellent service. Also, soliciting other businesses from customers.</li>
  <li>Monthly financial analysis: including determining the overall success of current customer profiles.</li>
  <li>Determine current rates in the marketplace offered by competitors.</li>
  <li>Ensure accuracy and timeliness of data in Cargowise CRM and other programs as needed.</li>
  <li>Ensure all necessary documentation is accurate and timely.</li>
</ul>
`, experience: `<ul>
  <li>Experience with transportation and logistics industries.</li>
  <li>Proven record of growing business and starting new accounts.</li>
  <li>Ability to make quick, smart business decisions.</li>
  <li>Experience working in a proactive sales role, either on the phone or face-to-face.</li>
  <li>Proven experience in stakeholder management and networking to achieve results.</li>
  <li>Outstanding organizational skills and excellent time management skills, with the ability to multitask.</li>
  <li>Ability to work in a fast-paced environment with rapidly changing priorities.</li>
  <li>Demonstrated ability to negotiate and influence others both internally and externally.</li>
  <li>Bachelor's degree preferred.</li>
</ul>
`, benefits: `<ul>
  <li>Competitive base salary.</li>
  <li>Generous commission.</li>
  <li>Laptop.</li>
  <li>Mobile phone.</li>
  <li>Car allowance.</li>
</ul>`},
    {
      title: "Sales Coordinator", department: "Sales Department", location: "Milton Keynes", description: `The Logistics Sales Coordinator will support the sales team by being the first initial point of contact for new and 
existing one off client queries; with a particular emphasis on one off quotes for all transport modes and services,
offered by the organisation to meet the client’s requirements. This role will require attention to detail and 
experience in sales administration and a fast paced sales environment.`, responsibilites: `<ul>
  <li>Generate spot quotes.</li>
  <li>Demonstrate a positive attitude towards problem-solving.</li>
  <li>Contribute effectively in a team environment.</li>
  <li>Book meetings with clients with larger potential.</li>
  <li>Upsell services wherever possible.</li>
  <li>Create and maintain a relationship with clients, prospects, and Key Account Managers.</li>
  <li>Develop and manage new client quotes to achieve targets set.</li>
  <li>Day-to-day quote management and follow-up to close business.</li>
  <li>Work with internal teams to resolve client issues as needed.</li>
  <li>Maintain current customers, including analysis of current lanes to assure competitiveness and provide excellent service. Also, solicit other business from customers.</li>
  <li>Maintain awareness of current rates in the marketplace offered by competitors.</li>
  <li>Ensure accuracy of data in Cargowise CRM and other programs as needed.</li>
  <li>Marketing – Produce content and sales campaigns via Cargowise CRM and work within the sales department to promote offers and target markets to attract new customers, suppliers, and business partners.</li>
</ul>
`, experience: `<ul>
  <li>Experience with transportation and logistics industries (Preferred).</li>
  <li>Awareness of the Freight Forwarding industry (Preferred).</li>
  <li>Previous internal sales experience.</li>
  <li>Experience in Sales/Spot Quotes.</li>
  <li>IT literate.</li>
  <li>Experience working in a proactive sales role, either on the phone or face-to-face.</li>
  <li>Outstanding organizational skills and excellent time management skills, with the ability to multitask.</li>
  <li>Ability to work in a fast-paced environment.</li>
  <li>Demonstrated ability to negotiate both internally and externally.</li>
  <li>Experience with Cargowise CW1 (Preferred).</li>
  <li>Problem-solving ability.</li>
  <li>Good communication skills.</li>
  <li>Confident and tenacious.</li>
  <li>Flexible approach to work.</li>
  <li>Attention to detail.</li>
</ul>
`, benefits: `<ul>
  <li>Competitive base salary.</li>
  <li>Generous uncapped bonus.</li>
</ul>`,
      education: `A-levels or equivalent (Preferred)`,
    },
    {
      title: "Freight Account Manager", department: "Sales Department", location: "Milton Keynes", description: `The Logistics Freight Account Manager will support the organisation through being a single point of contact for
new and existing client account management with a particular emphasis on meeting the clients requirements. 
This role will plan and manage sales strategies that will maintain businesses longevity and effectively manage the 
logistics business of our clients.`, responsibilites: `<ul>
  <li>Direct and monitor the workflow of clients' businesses through sales and operational management.</li>
  <li>Develop and manage new accounts to achieve targets set.</li>
  <li>Account management – Existing customers need to be looked after. Customer retention and growth are vitally important.</li>
  <li>Sales activities – Calling, emailing, and consulting with customers to ascertain their potential requirements for our products and services.</li>
  <li>Achieve satisfactory profit/loss ratio and market share in relation to preset standards, industry and economic trends, and sales and margin goals.</li>
  <li>Day-to-day account management and development of new business.</li>
  <li>Work with internal teams to resolve account issues as needed.</li>
  <li>Ensure training and support for Cargowise Webtracker (iHub) system for clients to manage their supply chain.</li>
  <li>Manage sourcing of customers. Build and maintain relationships with customers and assist and direct team members in sourcing and growing the customer base.</li>
  <li>Maintenance of current customers: Including analysis of current lanes to assure competitiveness and provide excellent service. Also, soliciting other business from customers.</li>
  <li>Monthly financial analysis: Including determining the overall success of current customer profiles.</li>
  <li>Determine current rates in the marketplace offered by competitors.</li>
  <li>Ensure accuracy and timeliness of data in Cargowise CRM and other programs as needed.</li>
  <li>Ensure all necessary reports and reporting are accurate and timely.</li>
  <li>Marketing – Produce content and sales campaigns via Cargowise CRM and work within the marketing department to raise the profile of the business in target markets to attract new customers, suppliers, and business partners.</li>
</ul>
`, experience: `<ul>
  <li>Experience with transportation and logistics industries.</li>
  <li>Proven record of growing business and starting new accounts.</li>
  <li>Ability to make quick, smart business decisions.</li>
  <li>Experience working in a proactive sales role, either on the phone or face-to-face.</li>
  <li>Proven experience in stakeholder management and networking to achieve results.</li>
  <li>Outstanding organizational skills, excellent time management skills, and the ability to multitask.</li>
  <li>Ability to work in a fast-paced environment with rapidly changing priorities.</li>
  <li>Demonstrated ability to negotiate and influence others both internally and externally.</li>
  <li>Bachelor’s degree preferred.</li>
</ul>
`, benefits: `<ul>
  <li>Competitive base salary.</li>
  <li>Generous commission.</li>
  <li>Laptop.</li>
  <li>Mobile phone.</li>
</ul>
`},
    {
      title: "Freight Coordinator", department: "Operations", location: "Milton Keynes", description: `The Freight Coordinator is the first point of operational contact for our clients. Responsible for booking in and 
managing the documentation and collection part of the logistical process, providing excellent customer service.
,`, responsibilites: `<ul>
  <li>Ensure that bookings (Air, Sea, or Road) are processed in a timely manner.</li>
  <li>Build and maintain strong relationships with new and existing clients.</li>
  <li>Provide a fantastic customer experience.</li>
  <li>Use initiative for problem solving.</li>
  <li>Schedule Air/Sea/Road freight bookings by following KPIs.</li>
  <li>Liaise with shippers for export documentation such as commercial invoices/packing lists to complete documentation formalities.</li>
  <li>Create, maintain, and update booking notes.</li>
  <li>Keep clients updated on their tickets.</li>
  <li>Check the details of commercial invoices and packing lists to ensure our system is updated with correct and accurate information.</li>
  <li>Liaise with key suppliers to arrange bookings.</li>
  <li>Ensure deadlines are achieved and overall customer satisfaction.</li>
  <li>Ensure workflow and tasks are managed effectively.</li>
</ul>
`, experience: `<ul>
 <li>Attention to details and use initiative.</li>
 <li>Ability to make quick, smart business decisions.</li>
 <li>Bachelor’s degree preferred.</li>
 <li>Outstanding organizational skills, excellent time management skills, ability to multitask.</li>
 <li>Ability to work in a fast-paced environment with rapidly changing priorities</li>`,
      benefits: `<ul>
  <li>Competitive base salary.</li>
  <li>Laptop.</li>
  <li>Mobile phone.</li>
</ul>`},
  ];

  ngAfterViewInit() {
    this.ExploreSide!.nativeElement.style.display = 'none';
    this.AllDeptsSide!.nativeElement.style.display = 'none';
  }

  back() {
    this.ExploreSide!.nativeElement.style.display = 'none';
    this.AllDeptsSide!.nativeElement.style.display = 'none';
    this.Explore!.nativeElement.style.display = 'block';
    this.AllDepts!.nativeElement.style.display = 'block';
  }

  openJob(currentIndex: number) {
    this.Explore!.nativeElement.style.display = 'none';
    this.AllDepts!.nativeElement.style.display = 'none';
    this.ExploreSide!.nativeElement.style.display = 'block';
    this.AllDeptsSide!.nativeElement.style.display = 'block';
    this.questionIndex = currentIndex;
    this.currentJob = this.faqQuestions[currentIndex];
  }
  @ViewChild('answer') answer: ElementRef | undefined;
  @ViewChild('question') question: ElementRef | undefined;
  @ViewChild('backIcon') backIcon: ElementRef | undefined;
  questionIndex: any;
  currentJob = {
    title: '',
    department: '',
    location: '',
    description: '',
    responsibilites: '',
    experience: '',
    benefits: '',
    education: ''

  };
  ngOnInit(): void {

    function toCapitalize(str: any): string {
      if (!str) return '';
      return str.charAt(0).toUpperCase() + str.slice(1);
  }
  this.title.setTitle(toCapitalize("Careers") + " - Intercargo Logistics");
   
  this.meta.removeTag('name="author"');
  this.meta.removeTag('property="og:title"');
  this.meta.removeTag('property="og:description"');
  // this.meta.removeTag('property="og:image"');
  this.meta.addTags([
    { name: 'keywords', content: 'Logistics, cargo, air freight, sea freight' },
    { name: 'author', content: 'Intercargo Logistics' },
    { property: 'og:title', content: 'Careers' },
    {
      property: 'og:description', content: "You have unique experiences, skills and passions, find your Role at Intercargo." },
    // { property: 'og:image', content: 'https://www.intercargo.com/assets/imgs/AirRight1.png' }
  ]); 
  }

  openFAQ(question: any) {
    this.questionIndex = question
    if (this.backIcon) {
      this.backIcon.nativeElement.style.display = 'block';
    }
    if (this.question) {
      this.question.nativeElement.style.display = 'none';
    }
    if (this.answer) {
      this.answer.nativeElement.style.display = 'block';
    }
  }
  rightImageData = {
    heading:
      'You have unique experiences, skills and passions, find your Role at Intercargo',
    description: ``,
    img: '../../../assets/videos/Career.mp4',
    btnTxt1: 'Communication',
    btnLink1: '',
    btnTxt2: 'Track Shipment',
    btnLink2: '',
  };
}
