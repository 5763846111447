import { Component, ElementRef, Inject, PLATFORM_ID, ViewChild } from '@angular/core';
import { FaqServiceService } from '../../../services/faq-service.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { isPlatformBrowser } from '@angular/common';
import { Meta, Title } from '@angular/platform-browser';


@Component({
  selector: 'app-faqs',
  templateUrl: './faqs.component.html',

  styleUrls: ['./faqs.component.css', '../../../shared/style.css'],
})

export class FaqsComponent {
  @ViewChild('answer') answer: ElementRef | undefined;
  @ViewChild('question') question: ElementRef | undefined;
  @ViewChild('backIcon') backIcon: ElementRef | undefined;

  constructor(private faqService: FaqServiceService, private sanitizer: DomSanitizer, @Inject(PLATFORM_ID) private platformId: Object,private title: Title, private meta: Meta) { }

  public sanitizedAnswerText: SafeHtml | undefined;
  apiResponseData: any;
  sections: any[] = [];
  allQuestions: any[] = [];
  allAnswers: any[] = [];
  updatedDate: any;

  ngOnInit() {
    this.faqService.getFaqs().subscribe((data) => {
      this.apiResponseData = data;
      this.faqQuestions = data.articles.map((faq: any) => faq.title);
      this.allAnswers = data.articles.map((faq: any) => faq.body);
    });
    function toCapitalize(str: any): string {
      if (!str) return '';
      return str.charAt(0).toUpperCase() + str.slice(1);
  }
  this.title.setTitle(toCapitalize("FAQ's")  + " - Intercargo Logistics")
   
  this.meta.removeTag('name="author"');
  this.meta.removeTag('property="og:title"');
  this.meta.removeTag('property="og:description"');
  // this.meta.removeTag('property="og:image"');
  this.meta.addTags([
    { name: 'keywords', content: 'Logistics, cargo, air freight, sea freight' },
    { name: 'author', content: 'Intercargo Logistics' },
    { property: 'og:title', content: 'FAQs' },
    {
      property: 'og:description', content: "Looking for specific answers? Explore FAQ’s and learn more about us." },
    // { property: 'og:image', content: 'https://www.intercargo.com/assets/imgs/AirRight1.png' }
  ]); 

    this.faqService.getSections().subscribe((data) => {

      this.getSectionNames(data.sections);

      this.getFAQs(this.sections[0]);
    });

  }
  getSectionNames(data: any) {
    data.forEach((section: any) => {
      this.sections.push({ name: section.name, id: section.id });
    });

  }

  faqQuestions: string[] = [];
  answerText: any = [];

  isDisabled = true;
  activeItem = 'Finance';

  enableSearchButton() {
    this.isDisabled = false;
  }

  backToQuestions() {
    if (this.question) {
      this.question.nativeElement.style.display = 'block';
    }
    if (this.answer) {
      this.answer.nativeElement.style.display = 'none';
    }
    if (this.backIcon) {
      this.backIcon.nativeElement.style.display = 'none';
    }
  }

  getFAQs(faq: any) {
    this.activeItem = faq.name;

    if (this.backIcon) {
      this.backIcon.nativeElement.style.display = 'none';
    }
    if (this.question) {
      this.question.nativeElement.style.display = 'block';
    }
    if (this.answer) {
      this.answer.nativeElement.style.display = 'none';
    }

    this.faqQuestions = this.apiResponseData.articles
      .filter((article: any) => article.section_id == faq.id)
      .map((article: any) => article.title);

    this.allAnswers = this.apiResponseData.articles
      .filter((article: any) => article.section_id == faq.id)
      .map((article: any) => article.body);


  }
  questionIndex = 0;

  openFAQ(question: any) {
    this.questionIndex = question
    if (isPlatformBrowser(this.platformId)) {
      const answerTemp = this.allAnswers[question];
      const tempDiv = document.createElement('div');

      tempDiv.innerHTML = answerTemp;
      const images = tempDiv.querySelectorAll('img');

      images.forEach(img => {
        img.classList.add('img-fluid');
      });


      // Remove inline styles from all <p> tags
      const paragraphs = tempDiv.querySelectorAll('p');
      paragraphs.forEach(p => {
        p.removeAttribute('style');
      });

      // Remove inline styles from all <span> tags
      const spans = tempDiv.querySelectorAll('span');
      spans.forEach(span => {
        span.removeAttribute('style');
      });
      const updatedAnswerTemp = tempDiv.innerHTML;

      this.sanitizedAnswerText = this.sanitizer.bypassSecurityTrustHtml(updatedAnswerTemp);
      this.updatedDate = this.apiResponseData.articles[question].updated_at;
      this.updatedDate = new Date(this.updatedDate).toUTCString();
      if (this.backIcon) {
        this.backIcon.nativeElement.style.display = 'block';
      }
      if (this.question) {
        this.question.nativeElement.style.display = 'none';
      }
      if (this.answer) {
        this.answer.nativeElement.style.display = 'block';
      }
    }
  }

  checkInputValue(event: FocusEvent) {
    const inputElement = event.target as HTMLInputElement;
    if (!inputElement.value.trim()) {
      this.isDisabled = true;
    }
  }

  rightImageData = {
    heading:
      'Looking for specific answers? Explore FAQ’s and learn more about us',
    description: ``,
    img: '../../../assets/videos/FAQ.mp4',
    btnTxt1: 'Communication',
    btnLink1: '',
    btnTxt2: 'Track Shipment',
    btnLink2: '',
  };
}
