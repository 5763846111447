<div class="loading-overlay  " *ngIf="loader">
  <div class="spinner"></div>
</div>

<div class="bg-light container-main">

  <p class="fw-medium back-button d-flex align-items-center" id="back"
    style="cursor: pointer;" routerLink>
    <i class="bi bi-chevron-left fw-bolder h2-icon me-2"></i>
    Back to Intercargo.com
  </p>

  <div class="container-fluid">

    <h1 class="fs-2 text-primary fw-medium my-5" *ngIf="megaMenu">New Company
      Account Registration</h1>
    <!-- <h1 class="fs-2 text-primary fw-medium my-5" *ngIf="!megaMenu">Sign Up to
      Conexa<sup>&copy;</sup> </h1> -->
    <div class="d-flex flex-row align-items-center justify-content-center">
      <h1 class="fs-1 text-primary fw-medium my-5" *ngIf="!megaMenu">
        Sign Up to
        <img [src]="conexaLogo"
          alt="Conexa Logo"
          class="img-fluid pb-1 conexaLogo" />

      </h1>
    </div>
    <p *ngIf="!megaMenu">
      Get ready to streamline your logistics with Conexa: Expertly delivered.
    </p>
    <p *ngIf="megaMenu">
      Unlock a seamless logistics experience with Intercargo: Efficiency
      redefined.
    </p>

    <span>
      Please note this registration is intended for companies and agents only.
    </span>

    <div class="container form-container">
      <form [formGroup]="registrationForm!" (ngSubmit)="onSubmit()">
        <div class="form-row row">
          <div class="form-group col-md-6" *ngIf="!megaMenu">
            <label for="customerID" class="form-label">Organisation Code</label>
            <input type="text" class="form-control" id="customerID"
              formControlName="customerID"
              [ngClass]="registrationForm!.get('customerID')?.value ? (validcustomerID ? 'is-valid' : 'is-invalid') : ''"
              placeholder="Enter Company Code eg. INTERICJFK"
              (focusout)="onCustomerIDFocusOut()" />
            <sub class="text-primary"> Existing clients can use company code eg.
              INTERICJFK </sub>
            <div id="validationServercustomerID" class="invalid-feedback"
              *ngIf="!validcustomerID">
              Please provide a valid company code.
            </div>
          </div>

          <div class="form-group col-md-6" *ngIf="megaMenu">
            <label for="firstName" class="form-label">First Name</label>
            <input type="text" class="form-control py-3" id="firstName"
              formControlName="firstName"
              placeholder="Enter First Name" required />
            <div
              *ngIf="(registrationForm!.get('firstName')?.invalid && registrationForm!.get('firstName')?.touched) "
              class="text-danger">
              Please enter your first name.
            </div>

          </div>

          <div class="form-group col-md-6" *ngIf="megaMenu">
            <label for="lastName" class="form-label">Last Name</label>
            <input type="text" class="form-control py-3" id="lastName"
              formControlName="lastName"
              placeholder="Enter Last Name" required />
            <div
              *ngIf="registrationForm!.get('lastName')?.invalid && registrationForm!.get('lastName')?.touched"
              class="text-danger">
              Please enter your last name.
            </div>
          </div>

          <div class="form-group col-md-3" *ngIf="!megaMenu">
            <label for="firstName" class="form-label">First Name</label>
            <input type="text" class="form-control py-3" id="firstName"
              formControlName="firstName"
              placeholder="Enter First Name" required />
            <div
              *ngIf="registrationForm!.get('firstName')?.invalid && registrationForm!.get('firstName')?.touched"
              class="text-danger">
              Please enter your first name.
            </div>

          </div>

          <div class="form-group col-md-3" *ngIf="!megaMenu">
            <label for="lastName" class="form-label">Last Name</label>
            <input type="text" class="form-control py-3" id="lastName"
              formControlName="lastName"
              placeholder="Enter Last Name" required />
            <div
              *ngIf="registrationForm!.get('lastName')?.invalid && registrationForm!.get('lastName')?.touched"
              class="text-danger">
              Please enter your last name.
            </div>
          </div>

        </div>
        <div class="form-row row">
          <div class="form-group col-md-6">
            <label for="email" class="form-label">Email</label>
            <input type="email" class="form-control py-3" id="email"
              formControlName="email"
              placeholder="Enter your email" required />
            <div
              *ngIf="registrationForm!.get('email')?.invalid && registrationForm!.get('email')?.touched"
              class="text-danger">
              Please enter a valid email.
            </div>
          </div>
          <div class="form-group col-md-6">
            <label for="phone" class="form-label">Contact Number</label>
            <input type="text" class="form-control py-3" id="phone"
              formControlName="phone" placeholder="+4467456543"
              required />
            <div
              *ngIf="registrationForm!.get('phone')?.invalid && registrationForm!.get('phone')?.touched"
              class="text-danger">
              Please enter your contact number.
            </div>
          </div>
        </div>
        <div class="form-row row">

          <div class="form-group col-md-6">
            <label for="companyName" class="form-label">Company Name</label>
            <input type="text" class="form-control py-3" id="companyName"
              formControlName="companyName"
              placeholder="Enter Company Name" required [disabled]=true />
            <div
              *ngIf="registrationForm!.get('companyName')?.invalid && registrationForm!.get('companyName')?.touched"
              class="text-danger">
              Please enter your company name.
            </div>
          </div>

          <div class="form-group col-md-6">
            <label for="country" class="form-label">Country</label>
            <select id="country" class="form-select" formControlName="country"
              required (change)="onCountryChange($event)">
              <option value *ngIf="countries.length>1">Select Country</option>
              <option *ngFor="let country of countries"
                [value]="country.name">{{ country.name }}</option>
            </select>
            <div
              *ngIf="registrationForm!.get('country')?.invalid && registrationForm!.get('country')?.touched"
              class="text-danger">
              Please select your country.
            </div>
          </div>

        </div>

        <div class="form-row row">

          <div class="form-group col-md-6">
            <label for="address1" class="form-label">Address 1</label>
            <input type="text" class="form-control py-3" id="address1"
              formControlName="address1"
              placeholder="Address Line 1" required />
            <div
              *ngIf="registrationForm!.get('address1')?.invalid && registrationForm!.get('address1')?.touched"
              class="text-danger">
              Please enter your address.
            </div>
            <div class="text-danger"
              *ngIf="registrationForm!.get('address1')?.errors?.['maxlength']">Address
              cannot exceed 25 characters.</div>
          </div>

          <div class="form-group col-md-6">
            <label for="address2" class="form-label">Address 2</label>
            <input type="text" class="form-control py-3" id="address2"
              formControlName="address2"
              placeholder="Address Line 2" />
            <div
              *ngIf="registrationForm!.get('address2')?.invalid && registrationForm!.get('address2')?.touched"
              class="text-danger">
              Please enter your address.
            </div>
            <div class="text-danger"
              *ngIf="registrationForm!.get('address2')?.errors?.['maxlength']">Address
              cannot exceed 25 characters.</div>
          </div>

        </div>

        <div class="form-row row">

          <div class="form-group col-md-6">
            <label for="city" class="form-label">City</label>
            <div class="select-editable " class="form-group">
              <!-- <select
                id="city"
                class="form-select"
                formControlName="city"
                (input)="onCityInput($event)"
                (change)="onCityChange($event)">
                <option *ngIf="registrationForm!.get('country')?.value == ''"
                  value>Select Country First</option>
                <option *ngIf="loadingCities" value>Loading Cities...</option>
                <option *ngIf="!loadingCities && filteredCities.length > 0"
                  value>Select City</option>
                <option *ngFor="let city of filteredCities"
                  [value]="city.code">{{ city.name }}</option>
              </select> -->

              <ng-select
                [items]="filteredCities"
                bindLabel="name"
                bindValue="code"
                labelForId="state"
                [multiple]="false"
                [placeholder]="placeholder"
                [loadingText]="'Loading Cities...'"
                [notFoundText]="'No cities found'"
                [loading]="loadingCities"
                formControlName="city"
                (change)="onCityChange($event)"
                [searchable]="true"
                (focus)="onFocus()"
                (blur)="onBlur()"
                (search)="onSearch($event)">
              </ng-select>

            </div>

            <div
              *ngIf="(registrationForm!.get('city')?.invalid && registrationForm!.get('city')?.touched) || cityError"
              class="text-danger">
              Please select your city.
            </div>
          </div>

          <div class="form-group col-md-6">
            <label for="postCode" class="form-label">Post Code</label>
            <input type="text" class="form-control py-3" id="postCode"
              formControlName="postCode"
              placeholder="Enter Post Code" required />
            <div
              *ngIf="registrationForm!.get('postCode')?.invalid && registrationForm!.get('postCode')?.touched"
              class="text-danger">
              Please enter your postal code.
            </div>
          </div>

        </div>
        <!-- <div class="form-row row">
          <div class="form-group col-12">
            <label for="input10">State:</label>
            <input type="text" class="form-control" id="input10" formControlName="state"
              placeholder="Provide State/Province" />
          </div>
        </div> -->

        <div class="form-row">
          <div class="form-group col-12">
            <div class="form-check d-flex align-items-center">
              <input class="form-check-input" type="checkbox" id="check1"
                formControlName="isFreightForwarder" />
              <label class="form-check-label" for="check1">
                Are you a Freight Forwarder?
              </label>
            </div>
          </div>
        </div>
        <div class="form-row row mt-5">
          <div class="form-group col-12 col-md-6">
            <button type="button" class="btn btn-outline-primary" routerLink>
              Cancel
            </button>
          </div>
          <div class="form-group col-md-6">
            <button type="submit" class="btn btn-primary">Register Now</button>
          </div>
        </div>
      </form>

    </div>
  </div>
</div>